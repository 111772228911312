<template>
  <div class="HospitalManagement">
    <a-spin :spinning="spinning">
<!--      选择省份城市查询-->
    <div class="searcherC">
      <div style="display: flex;align-items: center">
      <a-button style="margin-top: -68px">省份:</a-button>
      <a-button style="font-weight:bold;font-family: 微软雅黑;margin-top: -68px" size="small" @click="allChange" >全部</a-button>
      <div class="provinces" >
        <div   v-for="(item,index) in options"  :key="item.code"  >
          <a-button style="margin: 5px;font-weight:bold;font-family: 微软雅黑" size="small" :class="{ liBackground:provincesStyle == index}" id="provincesBtn"  @click="provincesChange(item.name,index)">{{item.name}}</a-button>
        </div>
      </div>
      </div>
      <a-divider :dashed="true" />
      <div style="display: flex;align-items: center">
      <a-button>城市:</a-button> &nbsp;
      <a-button style="font-weight:bold;font-family: 微软雅黑" size="small" @click="allCity" >全部</a-button>

      <div class="City">
        <span v-for="(item,index) in City.cityList" :key="item.value">
    <a-button style="margin: 5px;font-weight:bold;font-family: 微软雅黑" size="small" :class="{ liBackground:CityStyle == index}" id="cityBtn"  @click="CityChange(item.name,index)">{{item.name}}</a-button>
    </span>
      </div>
      </div>
    </div>
<!--      搜索医院-->
    <div class="Searcher">
      <div class="searchH">
        名称 &nbsp; <a-input v-model="searchValue.name" placeholder="输入名称" style="width: 210px" size="default" />
      </div>
      <div class="searchH">
        等级 &nbsp; <a-tree-select
          v-model="searchValue.grade"
          style="width: 150px"
          :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
          placeholder="选择等级"
          allow-clear
          tree-default-expand-all
      >
        <a-tree-select-node key="3" value="三级" title="三级">
          <a-tree-select-node key="3-1"  value="三级甲等" title="三级甲等" />
          <a-tree-select-node key="3-2" value="三级乙等" title="三级乙等" />
          <a-tree-select-node key="3-3" value="三级丙等" title="三级丙等" />
        </a-tree-select-node>
        <a-tree-select-node key="2" value="二级" title="二级">
          <a-tree-select-node key="2-1"  value="二级甲等" title="二级甲等" />
          <a-tree-select-node key="2-2" value="二级乙等" title="二级乙等" />
          <a-tree-select-node key="2-3" value="二级丙等" title="二级丙等" />
        </a-tree-select-node>
        <a-tree-select-node key="1" value="一级" title="一级">
          <a-tree-select-node key="1-1"  value="一级甲等" title="一级甲等" />
          <a-tree-select-node key="1-2" value="一级乙等" title="一级乙等" />
          <a-tree-select-node key="1-3" value="一级丙等" title="一级丙等" />
        </a-tree-select-node>
      </a-tree-select>
      </div>
      <div class="searchH">
        是否开通质控 &nbsp;<a-select  style="width: 120px;" placeholder="全部" @change="searchChange" >
        <a-select-option value="1">
          是
        </a-select-option>
        <a-select-option value="0">
          否
        </a-select-option>
      </a-select>
      </div>
      <div style="padding-left: 15px">
        <a-button @click="onSearch" >查询</a-button>
        <a-button style="margin-left: 10px" @click="searchCancel" >重置</a-button>
      </div>
    </div>
    <div class="hospital_list">
      <a-button type="primary" @click="()=>{this.visible = true}" style="margin-bottom: 10px" >
        新增
      </a-button>
      <a-table  :columns="columns" :data-source="HospitalList" :rowKey="record => record.id" :pagination="false"
      >
        <span slot="city" slot-scope="text,record">
          {{record.province}}/{{record.city}}
        </span>
        <span slot="condition" slot-scope="text,record">
        <a-switch :defaultChecked="record.is_sdicuzk=== 1 ? true:false" checked-children="是" un-checked-children="否" @click="switchFT(record.id,record.is_sdicuzk === 1? 0 : 1)" />
        </span>
        <span slot="operate" slot-scope="text,record">
          <a style="color: #1890ff" @click="Edit(record)">编辑</a>&nbsp;
          <a style="color: #1890ff" @click="delId(record.id)">删除</a>
        </span>

      </a-table>
      <div style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;margin-bottom: -10px">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="HospPagination" />
      </div>
    </div>


    <!------------------新增弹框----------->
    <a-drawer
        title="新增"
        :width="400"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{visible=false; this.spinning=false}"
    >
      <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" hideRequiredMark>
        <a-form-model-item label="名称"  prop="name">
          <a-input placeholder="输入名称" v-model="addForm.name" />
        </a-form-model-item>
        <a-form-model-item label="省份"  prop="province">
          <a-select placeholder="选择省份" v-model="addForm.province" style="width: 120px"  >
            <a-select-option v-for="(item,index) in options" :key="item.code" :value="item.name" @click="provinceAdd(item.name,index)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="城市" prop="city">
          <a-select placeholder="选择城市" v-model="addForm.city" style="width: 120px"  >
            <a-select-option v-for="item in CityNUM.cityList" :key="item.code" :value="item.name" @click="cityAdd(item.name)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="等级" prop="level">
          <a-tree-select
              v-model="addForm.level"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="选择等级"
              allow-clear
              tree-default-expand-all
          >
            <a-tree-select-node key="3" value="三级" title="三级">
              <a-tree-select-node key="3-1"  value="三级甲等" title="三级甲等" />
              <a-tree-select-node key="3-2" value="三级乙等" title="三级乙等" />
              <a-tree-select-node key="3-3" value="三级丙等" title="三级丙等" />
            </a-tree-select-node>
            <a-tree-select-node key="2" value="二级" title="二级">
              <a-tree-select-node key="2-1"  value="二级甲等" title="二级甲等" />
              <a-tree-select-node key="2-2" value="二级乙等" title="二级乙等" />
              <a-tree-select-node key="2-3" value="二级丙等" title="二级丙等" />
            </a-tree-select-node>
            <a-tree-select-node key="1" value="一级" title="一级">
              <a-tree-select-node key="1-1"  value="一级甲等" title="一级甲等" />
              <a-tree-select-node key="1-2" value="一级乙等" title="一级乙等" />
              <a-tree-select-node key="1-3" value="一级丙等" title="一级丙等" />
            </a-tree-select-node>
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="开通质控 " prop="switch">
          : &nbsp;<a-switch @change="switchChange" checked-children="是" un-checked-children="否" default-un-checked />
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="()=>{visible=false; this.spinning=false}">
          取消
        </a-button>
        <a-button type="primary" @click="addOk">
          确认
        </a-button>
      </div>
    </a-drawer>

<!--      修改医院信息-->
      <a-drawer
          title="编辑"
          :width="400"
          :visible="edit"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{edit=false; this.spinning=false}"
      >
        <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" hideRequiredMark>
          <a-form-model-item label="名称" prop="name">
            <a-input placeholder="输入名称" v-model="editForm.name" />
          </a-form-model-item>
          <a-form-model-item label="省份" prop="province">
            <a-select placeholder="选择省份" v-model="editForm.province" style="width: 120px"  >
              <a-select-option v-for="(item,index) in options" :key="item.code" :value="item.name" @click="provinceEdit(item.name,index)">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="城市" prop="city">
            <a-select placeholder="选择城市" v-model="editForm.city" style="width: 120px"  >
              <a-select-option v-for="item in CityNUM.cityList" :key="item.code" :value="item.name" @click="cityEdit(item.name)">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="等级" prop="level">
            <a-tree-select
                v-model="editForm.level"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                placeholder="选择等级"
                allow-clear
                tree-default-expand-all
            >
              <a-tree-select-node key="3" value="三级" title="三级">
                <a-tree-select-node key="3-1"  value="三级甲等" title="三级甲等" />
                <a-tree-select-node key="3-2" value="三级乙等" title="三级乙等" />
                <a-tree-select-node key="3-3" value="三级丙等" title="三级丙等" />
              </a-tree-select-node>
              <a-tree-select-node key="2" value="二级" title="二级">
                <a-tree-select-node key="2-1"  value="二级甲等" title="二级甲等" />
                <a-tree-select-node key="2-2" value="二级乙等" title="二级乙等" />
                <a-tree-select-node key="2-3" value="二级丙等" title="二级丙等" />
              </a-tree-select-node>
              <a-tree-select-node key="1" value="一级" title="一级">
                <a-tree-select-node key="1-1"  value="一级甲等" title="一级甲等" />
                <a-tree-select-node key="1-2" value="一级乙等" title="一级乙等" />
                <a-tree-select-node key="1-3" value="一级丙等" title="一级丙等" />
              </a-tree-select-node>
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="开通质控 ">
            : &nbsp;<a-switch  :checked="editForm.is_sdicuzk=== 0 ? false:true" @click="switchEdit(editForm.is_sdicuzk === 1? 0 : 1)" checked-children="是" un-checked-children="否"  />
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="()=>{edit=false; this.spinning=false}">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确认
          </a-button>
        </div>
      </a-drawer>
    </a-spin>
  </div>

</template>

<script>
import City from "@/assets/city.json"
import {delHospital, getHospital, getHospitalId, postHospitalAdd, putHospitalEdit} from "@/service/hospital_api";
export default {
  name: "Hospital",
  data(){
    return{
      sdicuzk:0,
      spinning:false,
      isShow:10,
      showMore:false,
      options: City ,
      City:'',
      treeExpandedKeys: [],
      visible : false,
      edit:false,
      searchValue: {
        name:'',
        grade:undefined,
        control:'',
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      addForm:{
        level:undefined,
        name:'',
        is_sdicuzk:'0',
        city:undefined,
        province:undefined
      },
      editForm:{
        city:'',
        province:''
      },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '序号',
          dataIndex: 'sort_index',
          width: '5%',
        },
        {
          title: '名称',
          dataIndex: 'name',
          width: '12%'
        },
        {
          title: '省份/城市',
          scopedSlots: { customRender: 'city' },
          width: '10%'
        },
        {
          title: '等级',
          dataIndex: 'level',
          width: '8%'
        },
        {
          title: '开通质控',
          width: '8%',
          scopedSlots: { customRender: 'condition' },
        },
        {
          title: '操作',
          width: '10%',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      rules: {
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        province:[{ required: true, message: '请选择省份', trigger: 'change' }],
        city:[{ required: true, message: '请选择城市', trigger: 'change' }],
        level:[{ required: true, message: '请选择等级', trigger: 'change' }],
      },
      HospitalList:[],
      CityNUM:'',
      CaseList:[],
      CityStyle:null,
      provincesStyle:null,
      provinceS:'',
      cityS:'',
      value:undefined,
      editLevel:undefined,
    }
  },
    created() {
    this.$store.dispatch('setManageHeaderTitle',"医院管理")
  },
  mounted() {
    this.getHospitalList()
  },
  methods:{
    // 获取医院列表
    async getHospitalList(data){
      this.spinning = true
      const result=await getHospital(data)
      if (result.code === 0){
        this.HospitalList = result.data.rows
        this.pagination.total = result.data.count
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning = false
    },
    async switchFT(id,sdicuzk){
      const data = {
        is_sdicuzk : sdicuzk
      }
      const switchS = await  putHospitalEdit(id,data)
      if (switchS.code === 0){
        this.$message.success('修改成功')
      }
    },
    // 分页
    async HospPagination(){
      this.spinning = true
      const Form = this.searchValue
      const data = {
        name : Form.name,
        level : Form.grade,
        sdicuzk : Form.control,
        pagenum: this.pagination.current,
        province : this.provinceS,
        city : this.cityS
      }

      await this.getHospitalList(data)

      this.spinning = false
    },
    // 质控开关
    switchChange(checked){
      if (checked === false){
        this.addForm.is_sdicuzk = '0'
      }else {
        this.addForm.is_sdicuzk = '1'
      }
    },
    //全部按钮
    allChange(){
      this.getHospitalList()
      this.provincesStyle = null
      this.City = ''
    },
    allCity(){
      this.CityStyle = null;
      this.cityS = ''
      const data = {
          province : this.provinceS
      }
      this.getHospitalList(data)
    },
    // 省份城市添加
    provinceAdd(value,index){
      this.CityNUM = this.options[index]
      this.addForm.province = value
      this.addForm.city = undefined
    },
    cityAdd(e){
      this.addForm.city = e
      this.provinceS = ''
    },
    //省份城市修改
    provinceEdit(value,index){
      this.CityNUM = this.options[index]
      this.editForm.province = value
    },
    cityEdit(e){
      this.editForm.city = e
    },
    // 选择省份城市搜索
    async provincesChange(e,index){
      this.provincesStyle = index;
      this.City = this.options[index]
      this.CityStyle= null
      this.provinceS = e
      this.cityS = undefined
      this.pagination.current = 1
      const data = {
        province : e
      }
      await this.getHospitalList(data)
    },
    // 城市选择
    async CityChange(e,index){
      this.CityStyle = index;
      this.cityS = e
      const data = {
        province : this.provinceS,
        city : e
      }
      await this.getHospitalList(data)
      this.pagination.current = 1
    },
    // 新增确认提交
    async addOk() {
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const addFrom = await postHospitalAdd(this.addForm)
          if (addFrom.code === 0){
            const Form = this.searchValue
            const data = {
              name : Form.name,
              level : Form.grade,
              sdicuzk : Form.control,
              pagenum: this.pagination.current
            }
            await this.getHospitalList(data)
            this.$message.success("添加成功")
            this.visible=false
            this.$refs.addItem.resetFields();
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // 展开编辑框
    async Edit(ref){
      this.edit=true;
      const id = ref.id
      const editId = await getHospitalId(id)
      if (editId.code ===0){
        this.editForm = editId.data
      }
      else {
        this.$message.error('获取接口失败'+ editId.message);
      }
    },
    // 修改质控
    switchEdit(sdicuzk){
      this.editForm.is_sdicuzk = sdicuzk
    },
    // 修改医院
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const id = this.editForm.id
          const edit = await putHospitalEdit(id,this.editForm)
          if (edit.code === 0){
            const Form = this.searchValue
            const data = {
              name : Form.name,
              level : Form.grade,
              sdicuzk : Form.control,
              pagenum: this.pagination.current
            }
            await this.getHospitalList(data)
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.edit=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // 删除
    async delId(id){
      const dalId = await delHospital(id)
      if (dalId.code === 0){
        this.$message.success('删除成功');
        const Form = this.searchValue
        const data = {
          name : Form.name,
          level : Form.grade,
          sdicuzk : Form.control,
          pagenum: this.pagination.current
        }
        await this.getHospitalList(data)
      }
    },
    // 医院搜索
    async onSearch(){
      const Form = this.searchValue
      const data = {
        name : Form.name,
        level : Form.grade,
        sdicuzk : Form.control,
        province : this.provinceS,
        city : this.cityS
      }
      await this.getHospitalList(data)
      this.pagination.current = 1
    },
    // 质控选择
    searchChange(value){
      this.searchValue.control = value
    },
    // 选择重置
    searchCancel(){
      this.getHospitalList()
      this.searchValue = {}
      this.cityS = ''
      this.provinceS = ''
      this.CityStyle= null
      this.provincesStyle =null
    }

  },

}
</script>

<style lang="scss" scoped>
.liBackground {
  background: #1890ff !important;
  color: white;
}

.HospitalManagement{
  margin: -15px -15px;
  background-color: whitesmoke;
  .searcherC{
    padding: 15px;
    margin: 0 auto;
    background-color: white;
    height: auto;
    font-size: 15px;
    .ant-btn{
      border: none !important;
      box-shadow:none !important;
    }
    .provinces{
      margin-left: 20px;
      display: flex;
      flex-wrap: wrap;
    }
    .City{
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
    }
  }
  .Searcher{
    padding: 5px;
    height: 120px;
    width: 100%;
    margin: 10px auto;
    background-color: white;
    .searchH{
      margin: 20px;
      display: inline-flex;
      align-items: center;
    }
  }
  .hospital_list{
    background-color: white;
    margin: 0 auto;
    padding: 15px;
  }
}
</style>
