// 医院管理api

import {get,post,put,del} from "./base";
// 获取医院列表
export function getHospital(data){
    return get('/v3/hospitals',data)
}
export function getHospitalId(id){
    return get('/v3/hospital/'+id)
}
// 添加医院
export function postHospitalAdd(data){
    return post('/v3/hospital',data)
}
// 修改医院信息
export function putHospitalEdit(id,data){
    return put('/v3/hospital/'+id,data)
}
// 删除医院
export function delHospital(id){
    return del('/v3/hospital/'+id)
}